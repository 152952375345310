import Vue from "vue";
import VueRouter from "vue-router";
import SignUp from "@/views/SignUp";
import Login from "@/views/Login";
import DashboardDynamicView from "@/views/DashboardDynamicView";
import store from "@/store";
import { auth } from "@/firebase/config";
// import { customerHasValidSubscription } from "@/helpers/customer-helpers";

Vue.use(VueRouter);

function rejectSignedInOnAuthRoutes(to, from, next) {
  // no auth route access in the demo
  if (process.env.VUE_APP_ENV === 'demo') return next(from);

  // reject users who are already signed in
  if (store.getters["auth/authed"])
    return next(to.query.redirect || from || "/dashboard");

  next();
}

async function mustBeActiveCustomer(to, from, next) {
  const hasAnySubscription = await store.dispatch("customers/hasAnySubscription", to.params.code);
  console.debug("[router] mustBeActiveCustomer", to, hasAnySubscription);
  return hasAnySubscription ? next() : next({ name: "lga-billing-info", params: to.params });
}

// async function checkSuffixMatchesExistingCustomer(to, from, next) {
//   // get suffix from email
//   const suffix = store.getters["auth/emailSuffix"];
//   if (!suffix) return next("/404");

//   // get lga from email suffix
//   const lga = await store.dispatch("lgaData/lgaFromEmailSuffix", suffix);
//   if (!lga) return next("/404");

//   // check for customer status
//   const customer = await store.dispatch("customers/loadCustomer", lga.lga_code);
//   if (customerHasValidSubscription(customer)) {
//     return next(`/lga/${lga.lga_code}/billing-info`);
//   }

//   next();
// }

const routes = [
  {
    path: "/",
    redirect() {
      return store.getters["auth/authed"] ? "/dashboard" : "/home";
    },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp,
    beforeEnter: rejectSignedInOnAuthRoutes,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: rejectSignedInOnAuthRoutes,
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(to, from, next) {

      if (process.env.VUE_APP_ENV === 'demo') return next(from);

      store.dispatch("auth/logout").then(() => {
        if (from.meta.requiresAuth) {
          return next("/login");
        }
        location.reload();
      });
    },
    meta: { requiresAuth: true },
  },
  {
    path: "/quote/:id",
    name: "quote",
    component: () => import("@/views/QuoteView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/signin-link",
    name: "signin-link",
    component: () => import("@/views/SigninLinkView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardDynamicView,
    meta: {
      requiresAuth: true,
      requiresLocalInstall: false,
    },
  },
  {
    path: "/map",
    component: () => import(/* webpackChunkName: "LeafletPublicMapView" */ "@/views/LeafletPublicMapView.vue"),
    meta: {
      requiresAuth: false,
      requiresLocalInstall: true,
    },
  },
  {
    path: "/staff-map",
    name: "staff-map",
    component: () => import(/* webpackChunkName: "LeafletStaffMapView" */ "@/views/LeafletStaffMapView.vue"),
    meta: {
      requiresStaff: true,
      requiresLocalInstall: true,
      requiresActiveCustomer: true,
    }
  },
  {
    path: "/lga-embed",
    name: "lga-embed",
    component: () => import(/* webpackChunkName: "LgaEmbedView" */ "@/views/LgaEmbedView.vue"),
  },
  {
    path: "/admin/map",
    name: "admin-map",
    component: () => import(/* webpackChunkName: "mapView" */ "@/views/LeafletAdminMapView.vue"),
    meta: {
      requiresAdmin: true,
      requiresLocalInstall: true,
    }
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import(/* webpackChunkName: "staffIncidentReportView" */ "@/views/StaffIncidentReportView.vue"),
    meta: {
      requiresAuth: true,
      requiresStaff: true,
      requiresRole: "submission_moderator",
      requiresActiveCustomer: true,
    },
  },
  {
    path: "/my-reports",
    name: "my-reports",
    component: () => import(/* webpackChunkName: "staffIncidentReportView" */ "@/views/MyIncidentReportsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reports/:reportId",
    component: () => import(/* webpackChunkName: "IncidentReportDetailVIew" */ "@/views/IncidentReportDetailView.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      canViewIncidentReportDetails: true,
    },
  },
  {
    path: "/report-clusters",
    component: () => import(/* webpackChunkName: "IncidentReportClustersView" */ "@/views/IncidentReportClustersView.vue"),
    meta: {
      requiresAuth: true,
      requiresRole: "submission_moderator",
      requiresActiveCustomer: true,
    },
    children: [
      { path: "pending", name: "pending-report-clusters", component: () => import("@/views/IncidentReportClustersContentView"), props: { status: "pending" } },
      { path: "approved", name: "approved-report-clusters", component: () => import("@/views/IncidentReportClustersContentView"), props: { status: "approved" } },
      { path: "rejected", name: "rejected-report-clusters", component: () => import("@/views/IncidentReportClustersContentView"), props: { status: "rejected" } },
      { path: "", redirect: "pending" },
    ]
  },
  {
    path: "/report-clusters/:clusterId",
    component: () => import(/* webpackChunkName: "IncidentReportClusterDetailView" */ "@/views/IncidentReportClusterDetailView.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      requiresRole: "submission_moderator",
      requiresActiveCustomer: true,
    }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import(/* webpackChunkName: "notifications" */ "@/views/NotificationsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notifications/:id",
    name: "notification-detail",
    component: () => import(/* webpackChunkName: "notification-detail" */ "@/views/NotificationDetailView.vue"),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/UserProfileManagement.vue"),
    meta: {
      requiresAuth: true,
      requiresLocalInstall: true,
    },
    children: [
      { path: "", name: "profile", component: () => import("@/views/UserProfileEditView.vue") },
      { path: "map-view", component: () => import("@/views/UserProfileEditMapView.vue") },
      { path: "notifications", component: () => import("@/views/UserProfileEditNotificationsView.vue") },
    ]
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "HomeView" */ "@/views/HomeView.vue"),
    beforeEnter(to, from, next) {
      if (store.getters["auth/authed"]) return next("/dashboard");
      // if (window.matchMedia('(display-mode: standalone)').matches)
      //   return next("/dashboard");
      next();
    },
  },

  {
    path: "/welcome",
    name: "welcome",
    component: () => import(/* webpackChunkName: "welcome" */ "@/views/Welcome.vue"),
    meta: {
      requiresAuth: true,
      requiresLocalInstall: true,
    },
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_ENV === 'demo') {
        if (from.name === "welcome" || from.name === "initialise") {
          return next("/dashboard");
        }
        return next(from);
      }
      next();
    }
  },
  {
    path: "/closures",
    component: () => import(/* webpackChunkName: "staffClosureManagement" */ "@/views/StaffClosureManagementView.vue"),
    meta: {
      requiresStaff: true,
      requiresRole: "closure_administrator",
      requiresActiveCustomer: true,
    },
    children: [
      { path: "active", component: () => import("@/views/StaffClosureManagementCurrentlyActiveView.vue") },
      { path: "archived", component: () => import("@/views/StaffClosureManagementArchivedView.vue") },
      { path: "scheduled", component: () => import("@/views/StaffClosureManagementScheduledView.vue") },
      { path: "deleted", component: () => import("@/views/StaffClosureManagementDeletedView.vue") },
      { path: "recently-opened", component: () => import("@/views/StaffClosureManagementRecentlyOpenedView.vue") },
      { path: "reopening-scheduled", component: () => import("@/views/StaffClosureManagementReopeningScheduledView.vue") },
      { path: "reopening-overdue", component: () => import("@/views/StaffClosureManagementReopeningOverdueView.vue") },
      { path: "stale", component: () => import("@/views/StaffClosureManagementStaleView.vue") },
      { path: "", redirect: "active", name: "closures" },
    ],
  },
  {
    path: "/closures/:id",
    props: true,
    component: () => import(/* webpackChunkName: "closureDetailView" */ "@/views/ClosureDetailView.vue"),
    children: [
      { path: "", name: "closure-detail", component: () => import("@/views/ClosureDetailsOverviewView.vue") },
      { path: "media", component: () => import("@/views/ClosureDetailsMediaView.vue") },
      { path: "activities", component: () => import("@/views/ClosureDetailsActivitiesView.vue") },
    ]
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: () => import(/* webpackChunkName: "UserSubscriptionsView" */ "@/views/UserSubscriptionsView.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "closures",
        component: () => import("@/views/ClosureSubscriptionView.vue"),
      },
      {
        path: "lgas",
        component: () => import("@/views/LgaSubscriptionView.vue"),
      },
      {
        path: "",
        redirect: "closures",
      }
    ]
  },
  {
    path: "/admin",
    name: "administration",
    component: () => import(/* webpackChunkName: "administration" */ "@/views/AdministrationView.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      { path: "lga-managers", component: () => import("@/views/AdministrationLGAManagersView.vue"), meta: { requiresLocalInstall: true } },
      { path: "lga-data", component: () => import("@/components/Administration/LGADataManagement.vue"), meta: { requiresLocalInstall: true } },
      { path: "customers", component: () => import("@/components/Administration/LGACustomerManagement.vue") },
      // { path: "checkout-sessions", component: () => import("@/views/OnboardingCheckoutSessionsListView.vue"), meta: { requiresLocalInstall: true } },
      { path: "*", redirect: "customers" },
    ],
  },
  {
    path: "/lga/:code",
    component: () => import(/* webpackChunkName: "lgaManagement" */ "@/views/LGAManagementView.vue"),
    props: true,
    meta: {
      requiresStaff: true,
      requiresRole: "account_manager",
      requiresActiveCustomer: false,
      isStaffOfLGA: true,
    },
    children: [
      {
        name: "lga-accounts",
        path: "accounts",
        component: () => import("@/views/LGAManagementAccountsView"),
        beforeEnter: mustBeActiveCustomer,
      },
      // {
      //   name: "lga-billing-info",
      //   path: "billing-info",
      //   component: () => import("@/views/LGAManagementBillingInfoView"),
      // },
      // {
      //   name: "lga-billing-history",
      //   path: "billing-history",
      //   component: () => import("@/views/LGAManagementBillingHistoryView"),
      //   beforeEnter: mustBeActiveCustomer,
      // },
      {
        path: "",
        redirect: "accounts",
      },
    ],
  },
  // {
  //   path: "/onboard",
  //   name: "customer-onboarding-welcome-view",
  //   component: () => import(/* webpackChunkName: "CustomerOnboardingWelcomeView" */ "@/views/CustomerOnboardingWelcomeView.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: "account_manager",
  //   },
  //   // beforeEnter: checkSuffixMatchesExistingCustomer,
  // },
  // {
  //   path: "/onboard/terms",
  //   name: "customer-onboarding-terms",
  //   component: () => import("@/views/CustomerOnboardingTerms"),
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: "account_manager",
  //   },
  //   // beforeEnter: checkSuffixMatchesExistingCustomer,
  // },
  // {
  //   path: "/onboard",
  //   name: "customer-onboarding-plan-choice",
  //   component: () => import("@/views/CustomerOnboardingPlanChoiceView"),
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: "account_manager",
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     // get suffix from email
  //     const suffix = store.getters["auth/emailSuffix"];
  //     if (!suffix) return next("/404");

  //     // get lga from email suffix
  //     const lga = await store.dispatch("lgaData/lgaFromEmailSuffix", suffix);
  //     if (!lga) return next("/404");

  //     // check for customer status
  //     const customer = await store.dispatch("customers/loadCustomer", lga.lga_code);
  //     if (customerHasValidSubscription(customer)) {
  //       return next(`/lga/${lga.lga_code}/billing-info`);
  //     }

  //     // also confirm the user has accepted the terms, otherwise we go back to /onboard/terms
  //     // if (!store.getters["userData/userData"]?.acceptedTermsAt) return next("/onboard/terms");

  //     // we're good to go!
  //     next();
  //   },
  // },
  // {
  //   path: "/onboard/processing",
  //   name: "onboarding-processing-view",
  //   component: () => import(/* webpackChunkName: "OnboardingProcessingView" */ "@/views/CustomerOnboardingProcessingView.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     requiresRole: "account_manager",
  //   },
  // },
  // {
  //   path: "/onboard/success",
  //   name: "customer-onboarding-success",
  //   component: () => import(/* webpackChunkName: "customerOnboardingSuccessView" */ "@/views/CustomerOnboardingSuccessView.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     suffixMatchesExistingCustomer: true,
  //     requiresRole: "account_manager",
  //   },
  //   beforeEnter: async (to, from, next) => {
  //     // get suffix from email
  //     const suffix = store.getters["auth/emailSuffix"];
  //     if (!suffix) return next("/404");

  //     // get lga from email suffix
  //     const lga = await store.dispatch("lgaData/lgaFromEmailSuffix", suffix);
  //     if (!lga) return next("/404");

  //     // check for customer status
  //     const customer = await store.dispatch("customers/loadCustomer", lga.lga_code);
  //     if (!customerHasValidSubscription(customer)) {
  //       return next(`/onboard/choose-plan`);
  //     }
  //     next();
  //   }
  // },
  {
    path: "/comms",
    component: () => import("@/views/CommunicationsManagerView"),
    meta: {
      requiresStaff: true,
      requiresRole: "media_comms_manager",
      requiresActiveCustomer: true,
    },
    children: [
      {
        path: "tasks",
        name: "socials-tasks",
        component: () => import(/* webpackChunkName: "ContentGenerationTasksView" */ "@/views/content-generation/TasksView.vue"),
      },
      {
        path: "integration",
        name: "website-integration",
        component: () => import("@/views/WebsiteIntegrationView"),
      },
      {
        path: "",
        redirect: "tasks",
      },
    ],
  },
  {
    path: "/comms/tasks/:taskId",
    name: "content-generation-tasks-detail",
    component: () => import(/* webpackChunkName: "ContentGenerationTaskDetailView" */ "@/views/content-generation/TaskDetailView.vue"),
    meta: {
      requiresStaff: true,
      requiresRole: "media_comms_manager",
      requiresActiveCustomer: true,
    },
  },
  {
    path: "/customer/:code",
    name: "customer-detail",
    component: () => import(/* webpackChunkName: "customerDetail" */ "@/views/CustomerDetailView.vue"),
    meta: {
      requiresAdmin: true,
    }
  },
  {
    path: "/init",
    name: "initialise",
    component: () => import(/* webpackChunkName: "initialisationView" */ "@/views/InitView.vue"),
  },
  {
    path: "/genseed",
    component: () => import("@/views/SeedGenerationView.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/legal/:document",
    component: () => import("@/views/LegalView"),
  },
  {
    path: "/legal",
    redirect: "/legal/terms",
  },
  {
    path: "/help",
    component: () => import("@/views/SupportView.vue"),
    children: [
      {
        path: "introduction",
        component: () => import("@/support/ClozureIntroduction"),
      },
      {
        path: "getting-started",
        component: () => import("@/support/GettingStarted"),
        name: "getting-started",
      },
      {
        path: "navigating-clozure",
        component: () => import("@/support/NavigatingClozure"),
      },
      {
        path: "subscriptions",
        component: () => import("@/support/Subscriptions"),
      },
      {
        path: "reporting-incidents",
        component: () => import("@/support/ReportingIncidents"),
      },
      // {
      //   path: "onboarding",
      //   component: () => import("@/support/OnboardingCouncil"),
      // },
      {
        path: "lga-management",
        component: () => import("@/support/LgaManagement"),
      },
      {
        path: "closure-administration",
        component: () => import("@/support/ClosureAdministration"),
      },
      {
        path: "report-moderation",
        component: () => import("@/support/ReportModeration"),
      },
      {
        path: "socials-content-generation",
        component: () => import("@/support/SocialsContentGeneration"),
      },
      {
        path: "website-integration",
        component: () => import("@/support/WebsiteIntegration"),
      },
      {
        path: "closure-types",
        component: () => import("@/support/ClosureTypes"),
      },
      {
        path: "road-conditions",
        component: () => import("@/support/RoadConditions"),
      },
      // scribes
      {
        path: "guide/marking-a-closure",
        component: () => import("@/components/ScribePage"),
        props: { scribeId: "Marking_a_Closure__EpehpauUQsKCQITI9iwtbg" },
      },
      {
        path: "guide/editing-a-closure",
        component: () => import("@/components/ScribePage"),
        props: { scribeId: "Update_Details_on_an_Existing_Closure__Vxqa6YI2SWiIB7BBKJMJ9w" },
      },
      {
        path: "guide/social-media-content-generation",
        component: () => import("@/components/ScribePage"),
        props: { scribeId: "Generating_Social_Media_Posts__GcXcZ9m5T6-THW1W1opiWA" },
      },
      {
        path: "guide/editing-existing-regions",
        component: () => import("@/components/ScribePage"),
        props: { scribeId: "Editing_RegionDetour_on_an_Existing_Closure__pHypPF3ZQxy5k2nhkRi-lA" },
      },
      {
        path: "guide/reopening-closures",
        component: () => import("@/components/ScribePage"),
        props: { scribeId: "Reopening_Closures__KsUeS7fXQnW1IiFcBsIuDw" },
      },
      // end of scribes
      {
        path: "",
        redirect: "introduction",
        name: "help",
      },
    ]
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404View.vue"),
    props: true,
  },
  {
    path: "*",
    component: () => import("@/views/404View.vue"),
    props: true,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

// check for auth on routes with 'requiresAuth' meta
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth.currentUser) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.query.redirect || to.fullPath }
      });
    }
  } else {
    next();
  }
});

// make sure the staff member's LGA is an active customer
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresActiveCustomer)) {
    const user = auth.currentUser;

    if (!user) return next("/login");

    const { claims } = await user.getIdTokenResult();

    if (claims.admin) return next();

    let code = claims.managed;
    let customer = await store.dispatch("customers/loadCustomer", code);

    const validStatuses = ["active", "trialing"];
    if (!validStatuses.includes(customer?.subscriptionStatus))
      return next("/dashboard");
  }
  return next();
})

// canViewIncidentReportDetails
// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some((record) => record.meta.canViewIncidentReportDetails)) {
//     const reportId = to.params.id;
//     const report = await store.dispatch("incidentReports/loadIncidentReport", reportId);
//     const ownsReport = report.source.payload.uid === store.getters["auth/serialisedUser"].uid;
//     const hasRole = await store.dispatch("userData/hasRole", "submission_moderator");

//     if (ownsReport || hasRole) {
//       next();
//       return;
//     }

//     next("/dashboard");
//   }
// });

// is staff of LGA as specified by :code URL param
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.isStaffOfLGA)) {
    const lgaCode = to.params.code;
    const staff = await store.dispatch("userData/isStaffOfLGA", lgaCode);
    if (!staff) {
      console.debug("[router] isStaffOfLGA", staff);
      next("/dashboard");
      return;
    }
  }
  next();
});

// check for specified role
router.beforeEach(async (to, from, next) => {
  const relevantRoutes = to.matched.filter((r) => r.meta.requiresRole);
  for (const record of relevantRoutes) {
    const hasRole = await store.dispatch("userData/hasRole", record.meta.requiresRole);
    if (!hasRole) {
      next({
        path: "/404",
        params: {
          message: "You do not have permission to view this resource",
        },
      });
      return;
    }
  }
  next();
});

// check for specific auth roles with 'requiresStaff' meta
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresStaff)) {
    const user = auth.currentUser;
    if (!user) return next("/login");
    const { claims } = await user.getIdTokenResult();
    if (claims.admin || claims.staff) {
      next();
    } else {
      next("/dashboard");
    }
  } else {
    next();
  }
});

// // check for specific auth roles with 'requiresLGAManagement' meta
// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresLGAManagement)) {
//     const canManage = await store.dispatch("lgaData/canManageLGA", to.params.code)
//     if (canManage) {
//       next();
//       return;
//     }
//     next("/dashboard");
//   } else {
//     next();
//   }
// });

// // check for specific auth roles with 'requiresLGAModeration' meta
// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresLGAModeration)) {
//     const canModerate = await store.dispatch("lgaData/canModerateLGA", to.params.code)
//     if (canModerate) {
//       next();
//       return;
//     }
//     next("/dashboard");
//   } else {
//     next();
//   }
// });

// check for specific auth roles with 'requiresAdmin' meta
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    const user = auth.currentUser;
    if (!user) return next("/login");
    const { claims } = await user.getIdTokenResult();
    if (claims.admin) {
      next();
    } else {
      next("/dashboard");
    }
  } else {
    next();
  }
});

// check for cached LGA data on 'requiresLocalInstall' meta
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLocalInstall)) {
    if (store.getters["lgaData/initialised"] && !store.getters["lgaData/updateRequired"]) {
      next();
    } else {
      next({
        path: "/init",
        query: { redirect: to.query.redirect || to.fullPath }
      });
    }
  } else {
    next();
  }
});

// check user email suffix matches a NEW customer (no subscription yet)
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.suffixMatchesNewCustomer)) {
    const suffix = store.getters["auth/emailSuffix"];
    if (!suffix) return next({ path: "/404", props: { message: "Invalid email address" } });
    const lga = await store.dispatch("lgaData/lgaFromEmailSuffix", suffix);
    if (!lga) return next({ path: "/404", props: { message: "Invalid LGA" } });
    // check for customer status
    const customer = await store.dispatch("customers/loadCustomer", lga.lga_code);
    if (customer?.subscriptionStatus == "active") {
      next("/onboard/success");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
