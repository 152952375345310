<template>
  <div class="dashboard-staff-view">
    <h1>Dashboard</h1>
    <p class="lead">{{ lga?.lga_name_long }}</p>
    <hr />
    <div
      class="d-flex flex-wrap mb-3"
      :style="{ gap: isMobile ? '30px' : '15px' }"
    >
      <template v-if="hasRole('account_manager')">
        <!-- <DashboardCardLgaBillingInfo class="flex-grow-1" /> -->
        <DashboardCardLgaPendingAccessRequests class="flex-grow-1" />
      </template>
      <template v-if="lga && hasRole('closure_administrator')">
        <DashboardCardLgaClosureSummary :lga="lga" class="flex-grow-1" />
        <DashboardCardLgaRecentClosureChanges
          can-administer
          :lga="lga"
          class="flex-grow-1"
        />
        <DashboardCardLgaOverdueClosures class="flex-grow-1" />
      </template>
      <template v-if="hasRole('submission_moderator')">
        <DashboardCardLgaPendingIncidentReports class="flex-grow-1" />
      </template>

      <template v-if="hasRole('media_comms_manager')">
        <DashboardCardLgaRecentSocialTasks class="flex-grow-1" />
      </template>
    </div>
  </div>
</template>

<script>
// import DashboardCardLgaBillingInfo from "@/components/DashboardCardLgaBillingInfo";
import DashboardCardLgaPendingAccessRequests from "@/components/DashboardCardLgaPendingAccessRequests";
import DashboardCardLgaClosureSummary from "@/components/DashboardCardLgaClosureSummary";
import DashboardCardLgaRecentClosureChanges from "@/components/DashboardCardLgaRecentClosureChanges";
import DashboardCardLgaPendingIncidentReports from "@/components/DashboardCardLgaPendingIncidentReports";
import DashboardCardLgaRecentSocialTasks from "@/components/DashboardCardLgaRecentSocialTasks";
import DashboardCardLgaOverdueClosures from "@/components/DashboardCardLgaOverdueClosures";
import { db, auth } from "@/firebase/config";
import { doc, onSnapshot } from "firebase/firestore";

export default {
  name: "dashboard-staff-view",

  components: {
    // DashboardCardLgaBillingInfo,
    DashboardCardLgaPendingAccessRequests,
    DashboardCardLgaClosureSummary,
    DashboardCardLgaRecentClosureChanges,
    DashboardCardLgaPendingIncidentReports,
    DashboardCardLgaRecentSocialTasks,
    DashboardCardLgaOverdueClosures,
  },

  data: () => ({
    userRoles: [],
    unsub: null,
  }),

  computed: {
    user() {
      return auth.currentUser;
    },

    lga() {
      return this.$store.getters["userData/assignedLGAs"]?.[0];
    },
  },

  watch: {
    user() {
      this.loadUserRoles();
    },
    lga() {
      this.loadUserRoles();
    },
  },

  mounted() {
    this.loadUserRoles();
  },

  beforeDestroy() {
    if (this.unsub) this.unsub();
  },

  methods: {
    hasRole(role) {
      return this.userRoles.includes(role);
    },

    loadUserRoles() {
      if (!(this.user?.email && this.lga?.lga_code)) return;
      this.unsub = onSnapshot(
        doc(db, "lga_data", this.lga.lga_code, "managers", this.user.email),
        (snapshot) => {
          const { roles } = snapshot.data();
          this.userRoles = roles;
        },
        (e) => {
          console.error(`Failed to determine your access roles: ${e.message}`);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
