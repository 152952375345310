import { db } from "@/firebase/config";
import { manyFromSnapshot, oneFromSnapshot } from "@/firebase/firestore-helpers";
import { onSnapshot, collection, doc, query, where, getDoc, addDoc, getDocs } from "firebase/firestore";
import { customerHasValidSubscription, customerHasCancelledSubscription } from "@/helpers/customer-helpers";

export default {
    namespaced: true,

    state: {
        customers: [],
        activeCustomer: { loading: true },
        invoices: [],
        subscriptions: [],
        product: null,
        prices: [],
    },

    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers
        },
        SET_ACTIVE_CUSTOMER(state, customer) {
            state.activeCustomer = customer
        },
        SET_INVOICES(state, invoices) {
            state.invoices = invoices;
        },
        SET_SUBSCRIPTIONS(state, subscriptions) {
            state.subscriptions = subscriptions;
        },
        SET_PRODUCT(state, product) {
            state.product = product;
        },
        SET_PRICES(state, prices) {
            state.prices = prices;
        },
    },

    actions: {
        async loadCustomer({ commit }, lga_code) {
            const docRef = doc(db, "customers", lga_code);
            const snapshot = await getDoc(docRef);
            const customer = oneFromSnapshot(snapshot);
            commit("SET_ACTIVE_CUSTOMER", customer);
            return customer;
        },

        async loadProduct({ commit }, classification) {
            const docRef = doc(db, "products", classification);
            const snapshot = await getDoc(docRef);
            const product = { ...snapshot.data(), classification: snapshot.id };
            commit("SET_PRODUCT", product);
            return product;
        },

        async loadPrices({ commit }, productId) {
            const q = query(
                collection(db, "prices"),
                where("product", "==", productId),
                where("active", "==", true)
            );
            const snapshot = await getDocs(q);
            const prices = manyFromSnapshot(snapshot);
            commit("SET_PRICES", prices);
            return prices;
        },

        subAllCustomers({ commit }, onError = null) {
            const onSnap = (snapshot) => {
                const customers = [];
                snapshot.forEach(async (doc) => {
                    customers.push({
                        paymentStatus: "none",
                        subscriptionStatus: "none",
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                commit("SET_CUSTOMERS", customers);
            };
            if (!onError) {
                onError = (e) => {
                    console.error(`Failed to subscribe to customers: ${e.message}`);
                };
            }
            const customersRef = collection(db, "customers");
            return onSnapshot(customersRef, onSnap, onError);
        },

        subCustomer({ commit }, { lga_code, onError }) {
            const onSnap = (snapshot) => {
                if (snapshot.exists()) {
                    commit("SET_ACTIVE_CUSTOMER", {
                        paymentStatus: "none",
                        subscriptionStatus: "none",
                        ...snapshot.data(),
                        id: snapshot.id,
                    });
                } else {
                    commit("SET_ACTIVE_CUSTOMER", null);
                }
            };
            if (!onError) {
                onError = (e) => {
                    console.error(`Failed to subscribe to customer ${lga_code}: ${e.message}`);
                };
            }
            const customerRef = doc(db, "customers", lga_code);
            return onSnapshot(customerRef, onSnap, onError);
        },

        subInvoices({ commit }, { lga_code, onError }) {
            const onSnap = (snapshot) => {
                const invoices = [];
                snapshot.forEach(doc => {
                    invoices.push({ id: doc.id, ...doc.data() });
                });
                commit("SET_INVOICES", invoices);
            };
            if (!onError) {
                onError = (e) => {
                    console.error(`Failed to subscribe to invoices for ${lga_code}: ${e.message}`);
                }
            }
            const invoicesRef = collection(db, "customers", lga_code, "invoices");
            return onSnapshot(invoicesRef, onSnap, onError);
        },

        async loadSubscriptions(context, lgaCode) {
            const colRef = collection(db, "customers", lgaCode, "subscriptions");
            const snapshot = await getDocs(colRef);
            const subscriptions = manyFromSnapshot(snapshot);
            context.commit("SET_SUBSCRIPTIONS", subscriptions);
            return subscriptions;
        },

        async loadInvoices(context, lgaCode) {
            const colRef = collection(db, "customers", lgaCode, "invoices");
            const snapshot = await getDocs(colRef);
            const invoices = manyFromSnapshot(snapshot);
            context.commit("SET_INVOICES", invoices);
            return invoices;
        },

        subSubscriptions({ commit }, { lga_code, onError }) {
            const onSnap = (snapshot) => {
                const subscriptions = [];
                snapshot.forEach(doc => {
                    subscriptions.push({ id: doc.id, ...doc.data() });
                });
                commit("SET_SUBSCRIPTIONS", subscriptions);
            };
            if (!onError) {
                onError = (e) => {
                    console.error(`Failed to subscribe to subscriptions for ${lga_code}: ${e.message}`);
                }
            }
            const subscriptionsRef = collection(db, "customers", lga_code, "subscriptions");
            return onSnapshot(subscriptionsRef, onSnap, onError);
        },

        async lgaCodeFromStripeId(context, stripeId) {
            const q = query(collection(db, "customers"), where("customerId", "==", stripeId));
            const snapshot = await getDoc(q)
            if (snapshot.exists()) return snapshot.id;
            throw new Error(`Customer with stripe ID ${stripeId} could not be found`);
        },

        async customerExists(context, lga_code) {
            const customerRef = doc(db, "customers", lga_code);
            const snap = await getDoc(customerRef);
            return snap.exists();
        },

        async hasAnySubscription(context, lga_code) {
            // now that we're not using the subscriptions collection, we need to check the customer data
            // we know that subStatus of 'none' is a new customer; 'canceled' is an ex-customer
            // so hasAnySubscription is true if the status is anything other than 'none'
            const customerRef = doc(db, "customers", lga_code);
            const snap = await getDoc(customerRef);
            const customerData = snap.data();
            return customerData?.subscriptionStatus !== "none";
        },

        async hasActiveSubscription(context, lga_code) {
            const snapshot = await getDoc(doc(db, "customers", lga_code));
            if (!snapshot.exists()) return false;
            return customerHasValidSubscription(snapshot.data());
        },

        async fetchDefaultTaxRate() {
            const snapshot = await getDocs(collection(db, "tax_rates"));
            const taxRates = manyFromSnapshot(snapshot);
            return taxRates.at(0);
        },

        async createOnboardingSession(context, { lga, price, poNumber, email, coupons }) {

            const taxRate = await context.dispatch("fetchDefaultTaxRate");
            const successUrl = window.location.origin + `/onboard/processing`;
            const cancelUrl = window.location.origin;

            const { classification, population, lga_code } = lga;

            const request = {
                mode: "subscription",
                success_url: successUrl,
                cancel_url: cancelUrl,

                line_items: [{
                    price: price.id,
                    tax_rates: [taxRate?.id],
                    quantity: 1,
                }],

                metadata: {
                    poNumber,
                    lga_code,
                    classification,
                    population,
                    subscriberEmail: email,
                },

                discounts: coupons.map((coupon) => ({ coupon })),
            }

            const data = { email, request, lga, price };
            const sessionRef = await addDoc(collection(db, "onboarding_checkout_sessions"), data);

            return sessionRef;
        },

        async createCheckoutSession(context, { lga, price, poNumber, skipTrial }) {
            // create URLs for returning from Stripe
            const successURL = window.location.origin + `/onboard/processing`;
            const cancelURL = window.location.origin + `/onboard`;

            console.debug("[customers] createCheckoutSession", lga, price, poNumber);

            const taxRate = await context.dispatch("fetchDefaultTaxRate");

            // create session payload
            const session = {
                mode: "subscription",
                line_items: [{
                    price: price.id,
                    tax_rates: [taxRate?.id],
                    quantity: 1,
                }],
                metadata: {
                    poNumber,
                    lga_code: lga.lga_code,
                    classification: lga.classification,
                    population: lga.population,
                    subscriber: context.rootGetters["auth/serialisedUser"].displayName,
                    subscriberEmail: context.rootGetters["auth/serialisedUser"].email,
                },
                success_url: successURL,
                cancel_url: cancelURL,
                allow_promotion_codes: true,
                billing_address_collection: "required",
            };

            if (!skipTrial) {
                session.subscription_data = {
                    trial_period_days: 30,
                };
            }

            // create the checkout session document
            const colRef = collection(db, "customers", lga.lga_code, "checkout_sessions");
            const sessionRef = await addDoc(colRef, session);

            // return the reference
            return sessionRef;
        },

        clearState({ state }) {
            console.debug("[customers] clearing state");
            state.customers = [];
            state.activeCustomer = null;
            state.invoices = [];
            state.subscriptions = [];
        },
    },

    getters: {
        customers: (state) => state.customers,
        activeCustomer: (state) => state.activeCustomer,
        invoices: (state) => state.invoices,
        subscriptions: (state) => state.subscriptions,
        product: (state) => state.product,
        prices: (state) => state.prices,

        isValidSubscriber({ activeCustomer }) {
            return customerHasValidSubscription(activeCustomer);
        },

        isExCustomer({ activeCustomer }) {
            return customerHasCancelledSubscription(activeCustomer);
        },
    }
}