const L = require("leaflet");
const polyUtil = require("polyline-encoded");
const moment = require("moment");

const VALHALLA_URL = process.env.VUE_APP_VALHALLA_URL ?? "https://valhalla1.openstreetmap.de";

const nameAndExtension = (filename) => {
  const i = filename.lastIndexOf(".")
  const name = filename.slice(0, i)
  const extension = filename.slice(i + 1)
  return { name, extension }
};

const rad = (deg) => {
  return deg * Math.PI / 180.0;
};

const haversineDistance = (p1, p2) => {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2[1] - p1[1]);
  var dLong = rad(p2[0] - p1[0]);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1[1])) * Math.cos(rad(p2[1])) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

const polylineLength = (latLngs) => {
  console.debug(`[polylineLength]`, { latLngs })
  let metres = 0;
  for (let i = 0; i < latLngs.length - 1; i++) {
    metres += haversineDistance(latLngs[i], latLngs[i + 1]);
  }
  const prettyDistance = (metres < 1000) ? `${Math.round(metres)}m` : `${(metres / 1000).toFixed(2)}km`;
  const unit = (metres < 1000) ? "m" : "km";

  return { metres, prettyDistance, unit };
}

const getPolylineCentroid = (google, path) => {
  if (path.length < 2) throw new Error("Not a valid polyline: path must contain at least two vertices");
  if (!google?.maps?.geometry?.spherical?.interpolate) throw new Error("Google object is invalid");
  const centroid = google.maps.geometry.spherical.interpolate(
    path[0],
    path[path.length - 1],
    0.5
  );
  const distances = path.map((latLng) => {
    const distance = haversineDistance(
      latLngToArray(centroid),
      latLngToArray(latLng)
    );
    return { latLng, distance };
  });
  distances.sort((a, b) => a.distance - b.distance);
  const closest = distances[0];
  return closest.latLng;
}

const pointInGeoJSON = (point, geometry) => {
  switch (geometry.type) {
    case "Polygon":
      return pointInPolygon(point, geometry.coordinates[0]) && !geometry.coordinates.slice(1).some(line => pointInPolygon(point, line));
    case "MultiPolygon":
      for (var poly of geometry.coordinates) {
        if (pointInPolygon(point, poly[0]) && !poly.slice(1).some(line => pointInPolygon(point, line)))
          return true;
      }
      break;
  }
  return false;
}


const polygonContainsOther = function (a, b) {
  const ba = new L.LatLngBounds();
  const bb = new L.LatLngBounds();

  a.forEach((point) => ba.extend(point));
  b.forEach((point) => bb.extend(point));

  return ba.contains(bb);
}


const polygonBoundsOverlap = function (a, b) {
  const ba = new L.LatLngBounds();
  const bb = new L.LatLngBounds();

  a.forEach((point) => ba.extend(point));
  b.forEach((point) => bb.extend(point));

  return ba.overlaps(bb);
}

/**
 * Performs the even-odd-rule Algorithm (a raycasting algorithm) to find out whether a point is in a given polygon.
 * This runs in O(n) where n is the number of edges of the polygon.
 *
 * @param {Array} polygon an array representation of the polygon where polygon[i][0] is the x Value of the i-th point and polygon[i][1] is the y Value.
 * @param {Array} point   an array representation of the point where point[0] is its x Value and point[1] is its y Value
 * @return {boolean} whether the point is in the polygon (not on the edge, just turn < into <= and > into >= for that)
 */
const pointInPolygon = function (point, polygon) {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let odd = false;
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
    //If a line from the point into infinity crosses this edge
    if (((polygon[i][1] > point[1]) !== (polygon[j][1] > point[1])) // One point needs to be above, one below our y coordinate
      // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
      && (point[0] < ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1]) / (polygon[j][1] - polygon[i][1]) + polygon[i][0]))) {
      // Invert odd
      odd = !odd;
    }
    j = i;

  }
  //If the number of crossings was odd, the point is in the polygon
  return odd;
};

const latLngToArray = (latLng) => {
  if (typeof latLng.lat === 'function') {
    return [latLng.lng(), latLng.lat()];
  } else {
    return [latLng.lng, latLng.lat];
  }
}

const latLngToLiteral = (latLng) => {
  if (typeof latLng.lat === 'function') {
    return { lat: latLng.lat(), lng: latLng.lng() };
  }
  // make sure it's a basic object and not a LatLng
  return { lat: latLng.lat, lng: latLng.lng };
}

const arrayToLatLng = (coords) => {
  return { lat: coords[1], lng: coords[0] };
}

const calculateLgaBounds = (geometry) => {
  let north, south, east, west;

  const processPolygon = (polygon) => {
    const lngs = polygon[0].map(point => point[0])
    const lats = polygon[0].map(point => point[1])
    north = Math.max(...lats);
    south = Math.min(...lats);
    west = Math.min(...lngs);
    east = Math.max(...lngs);
  }

  switch (geometry.type) {
    case "Polygon":
      processPolygon(geometry.coordinates);
      break;
    case "MultiPolygon":
      for (var poly of geometry.coordinates) {
        processPolygon(poly);
      }
      break;
  }

  return { north, south, east, west };
}

const chunkArray = (array, chunkSize) => {
  if (!array || !array.length) return [];
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result[Math.floor(i / chunkSize)] = array.slice(i, i + chunkSize);
  }
  return result;
}

const clamp = (from, to, val) => {
  if (val > to) return to;
  if (val < from) return from;
  return val;
}

async function snapToRoadsOSRM(coords) {
  const path = coords.map(({ lat, lng }) => `${lng},${lat}`).join(';');
  const url = `https://router.project-osrm.org/match/v1/driving/${path}`;
  const response = await fetch(url);
  const json = await response.json();

  if (json?.code === "Ok") {
    const encoded = json?.matchings?.map((m) => m.geometry)?.join("") || "";
    const decoded = polyUtil.decode(encoded);
    return decoded;
  } else {
    throw new Error(`Unable to snap to roads: ${json?.message}`);
  }
}

async function snapToRoadsGoogle(coords) {
  const path = coords.map(({ lat, lng }) => `${lat},${lng}`).join("|");
  const url = `https://roads.googleapis.com/v1/snapToRoads?key=${process.env.VUE_APP_MAPS_API_KEY}&interpolate=true&path=${path}`;
  const response = await fetch(url);
  const json = await response.json();
  return json.snappedPoints;
}

async function nearestLocationOSRM(coord) {
  const url = "https://router.project-osrm.org/nearest/v1/driving";
  const response = await fetch(`${url}/${coord.lng},${coord.lat}`);
  const json = await response.json();
  const { location: [lng, lat] } = json.waypoints[0];
  return [lat, lng];
}


// https://www.usna.edu/Users/oceano/pguth/md_help/html/approx_equivalents.htm
// 1.11m = 0.00001 degrees latitude (or longitude at the equator)
const METERS_TO_DEGREES_LATITUDE_FACTOR = 0.00001 / 1.11;

function metersToDegreesLatitude(meters) {
  return meters * METERS_TO_DEGREES_LATITUDE_FACTOR;
}

function polylineToPolygonPath(polyline, widthMeters) {
  const halfWidth = widthMeters * 0.5;
  const degLat = metersToDegreesLatitude(halfWidth);
  const south = structuredClone(polyline);
  const north = structuredClone(polyline).reverse();

  south.forEach((point) => {
    point.lat += degLat;
  });
  north.forEach((point) => {
    point.lat -= degLat;
  });

  const path = [...south, ...north, south[0]];

  const arr = path.map((point) => {
    // return { lat: point.lat, lon: point.lng };
    return [point.lat, point.lng];
  });

  console.debug("[util] polylineToPolygonPath", arr);
  return arr;
}


function boundsFromLiteral(literal) {
  const { north, south, east, west } = literal;
  const bounds = L.latLngBounds([
    [north, west],
    [south, east],
  ]);
  return bounds;
}

function titleCase(string) {
  return string
    .split(" ")
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(" ");
}

function boundsLiteralToLeafletBounds(bounds) {
  const { north, south, east, west } = bounds;
  return [[north, west], [south, east]];
}

function formatCentsAsCurrency(cents) {
  return (cents * 0.01).toLocaleString("en-AU", { style: "currency", currency: "AUD" });
}

function formatUnixTimestamp(format) {
  format = format || "lll";
  return function (timestamp) {
    return moment.unix(timestamp).format(format);
  }
}

function closureMarkerBounds(closure) {
  const bounds = L.latLngBounds();
  bounds.extend(closure.position || closure.center);
  if (closure.polyline)
    bounds.extend(L.polyline(closure.polyline).getBounds());
  if (closure.detourPolyline)
    bounds.extend(L.polyline(closure.detourPolyline).getBounds());
  return bounds;
}

async function snapToRoadsValhalla(coords) {
  // Format coordinates for Valhalla
  const shape = coords.map(({ lat, lng }) => ({
    lat: lat,
    lon: lng,
  }));

  // Construct the request body
  const requestBody = {
    shape,
    costing: "auto",
    shape_match: "map_snap", // Use map_snap for better results with GPS traces
    trace_options: {
      search_radius: 100, // 100 meters is upper limit for Valhalla
      gps_accuracy: 5,   // 5 meters GPS accuracy
    }
  };

  // Make request to Valhalla's trace_route endpoint
  const url = `${VALHALLA_URL}/trace_route`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  const json = await response.json();

  if (json.trip) {

    // Extract the matched points from the response
    const matchedPoints = json.trip.legs[0].shape;
    const decoded = polyUtil.decode(matchedPoints);

    // For some reason they're coming back in a weird format, so we need to normalize them
    return decoded.map(([lat, lng]) => ([lat / 10, lng / 10]))
  } else {
    // default error message
    let msg = `Unable to snap to roads: [${json.error_code}] ${json.error}`;

    // distance too great - is the distance customisable in Valhalla build?
    if (json.error_code === 172) {
      msg = "Distance between points is more than 10km. Please mark points closer together.";
    }
    else if (json.error_code === 443) {
      msg = "Too far from a road. Please zoom in and try again.";
    }
    // no path found, but also occurs right on the 10km limit before the 172 error
    else if (json.error_code === 442) {
      msg = "No path found. Points may be too far apart.";
    }

    throw new Error(msg);
  }
}

module.exports = {
  nameAndExtension,
  haversineDistance,
  polylineLength,
  polygonContainsOther,
  polygonBoundsOverlap,
  pointInPolygon,
  pointInGeoJSON,
  latLngToArray,
  latLngToLiteral,
  arrayToLatLng,
  calculateLgaBounds,
  chunkArray,
  clamp,
  getPolylineCentroid,
  snapToRoadsOSRM,
  snapToRoadsGoogle,
  nearestLocationOSRM,
  metersToDegreesLatitude,
  polylineToPolygonPath,
  boundsFromLiteral,
  titleCase,
  boundsLiteralToLeafletBounds,
  formatCentsAsCurrency,
  formatUnixTimestamp,
  closureMarkerBounds,
  snapToRoadsValhalla,
}