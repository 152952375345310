<template>
  <div>
    <b-navbar
      toggleable="md"
      type="light"
      variant="light"
      class="top-nav"
      style="z-index: 1001"
    >
      <b-container fluid>
        <b-navbar-brand :to="authed ? '/dashboard' : '/home'">
          <b-img
            width="24"
            height="24"
            src="/img/icons/favicon.svg"
            style="transform: translateY(-2px)"
            class="mr-1"
          />
          Clozure
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse">
          <template #default="{ expanded }">
            <fa-icon v-if="expanded" icon="chevron-up"></fa-icon>
            <fa-icon v-else icon="chevron-down"></fa-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-text v-if="!authed && isMobile">
              <small class="text-muted"> v{{ version }} </small>
            </b-nav-text>

            <b-nav-item
              exact
              exact-active-class="active"
              to="/dashboard"
              v-if="authed"
            >
              Dashboard
            </b-nav-item>

            <b-nav-item
              exact
              exact-active-class="active"
              to="/admin/"
              v-if="admin"
            >
              System Administration
            </b-nav-item>

            <!-- maps -->
            <b-nav-item
              exact
              exact-active-class="active"
              to="/map"
              v-if="!assignedLGAs.length || isNewCustomer"
            >
              Map
            </b-nav-item>
            <template v-else>
              <b-nav-item exact exact-active-class="active" to="/map">
                Public Map
              </b-nav-item>
              <b-nav-item
                exact
                exact-active-class="active"
                to="/staff-map"
                :disabled="isExCustomer"
              >
                Staff Map
              </b-nav-item>
            </template>
            <!-- <b-nav-item-dropdown v-if="administeredLGAs.length" text="Maps">
              <b-dropdown-item to="/staff-map" :disabled="isNewCustomer">
                Staff View
              </b-dropdown-item>
              <b-dropdown-item to="/map"> Public View </b-dropdown-item>
            </b-nav-item-dropdown> -->

            <!-- lga management, administration, moderation -->
            <b-nav-item-dropdown
              active-class="active"
              v-if="
                assignedLGAs?.length &&
                !isNewCustomer &&
                (canAdministerClosures ||
                  canModerateIncidentReports ||
                  canManageAccounts)
              "
              text="Manage LGA"
            >
              <b-dropdown-item
                exact
                exact-active-class="active"
                :disabled="!canManageAccounts"
                :to="`/lga/${managedLGAs[0]?.lga_code}/`"
              >
                User Role Assignment
              </b-dropdown-item>
              <b-dropdown-item
                exact
                exact-active-class="active"
                to="/closures"
                :disabled="
                  !canAdministerClosures || isNewCustomer || isExCustomer
                "
              >
                Administer Closures
              </b-dropdown-item>
              <b-dropdown-item
                exact
                exact-active-class="active"
                to="/report-clusters"
                :disabled="
                  !canModerateIncidentReports || isNewCustomer || isExCustomer
                "
              >
                Moderate Incident Reports
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <!-- media & content export -->
            <b-nav-item
              exact
              exact-active-class="active"
              v-if="canExportContent && !(isNewCustomer || isExCustomer)"
              to="/comms"
            >
              Communications
            </b-nav-item>
            <!-- <b-nav-item-dropdown v-if="canExportContent" text="Export">
              <b-dropdown-item :disabled="isNewCustomer" to="/comms/tasks">
                Generate Socials Content
              </b-dropdown-item>
              <b-dropdown-item :disabled="isNewCustomer">
                Manage Data &amp; Backups
              </b-dropdown-item>
            </b-nav-item-dropdown> -->

            <b-nav-item exact exact-active-class="active" to="/help">
              Help
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-3" v-if="staff">
            <b-button
              size="sm"
              variant="outline-primary"
              v-if="canRequestAccessToLGA"
              @click="onRequestAccess"
            >
              Request Access to LGA
            </b-button>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <NotificationsDropdown v-if="authed" />

            <b-nav-text v-if="!authed && !isMobile" class="mr-3">
              <small class="text-muted"> v{{ version }} </small>
            </b-nav-text>

            <b-nav-item
              exact
              exact-active-class="active"
              v-if="!authed"
              to="/legal"
            >
              Legal &amp; Privacy
            </b-nav-item>

            <b-nav-item
              exact
              exact-active-class="active"
              v-if="!authed"
              to="/login"
            >
              Sign In
            </b-nav-item>

            <b-nav-item-dropdown
              :text="
                (profile && profile.preferredName) ||
                user.displayName ||
                'Profile'
              "
              v-if="authed"
              right
            >
              <b-dropdown-item disabled>
                <span style="color: black">Clozure v{{ version }}</span>
              </b-dropdown-item>
              <b-dropdown-divider />

              <div v-if="notificationsAvailable">
                <b-dropdown-item-button
                  v-if="!fcmEnabled"
                  variant="outline-primary"
                  @click="!isDemo && onEnableMessaging()"
                  id="btn-enable-notifications"
                  :disabled="fcmLoading || notificationPermissionDenied"
                >
                  <fa-icon icon="bell" class="mr-2"></fa-icon>
                  Enable Push Notifications
                </b-dropdown-item-button>
                <b-tooltip
                  target="btn-enable-notifications"
                  triggers="hover"
                  :disabled="!isDemo"
                >
                  Disabled in the demo
                </b-tooltip>
                <b-dropdown-item-button
                  v-if="fcmEnabled"
                  variant="outline-primary"
                  @click="onDisableMessaging"
                  :disabled="fcmLoading"
                >
                  <fa-icon icon="bell-slash" class="mr-2"></fa-icon>
                  Disable Push Notifications
                </b-dropdown-item-button>
                <b-dropdown-divider />
              </div>

              <!-- if user has not installed the app -->
              <b-dropdown-item-button
                v-if="pwaInstallAvailable"
                @click="!isDemo && installPWA()"
                id="btn-install-pwa"
              >
                Install Clozure
              </b-dropdown-item-button>
              <b-tooltip
                v-if="pwaInstallAvailable"
                target="btn-install-pwa"
                triggers="hover"
                :disabled="!isDemo"
              >
                Disabled in the demo
              </b-tooltip>
              <b-dropdown-divider v-if="pwaInstallAvailable" />

              <b-dropdown-item
                exact
                exact-active-class="active"
                to="/subscriptions"
              >
                My Subscriptions
              </b-dropdown-item>
              <b-dropdown-item
                exact
                exact-active-class="active"
                to="/my-reports"
              >
                My Incident Reports
              </b-dropdown-item>
              <b-dropdown-item
                exact
                exact-active-class="active"
                to="/profile"
                :disabled="isDemo"
              >
                User Settings
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item exact exact-active-class="active" to="/legal">
                Legal &amp; Privacy
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item to="/logout" :disabled="isDemo">
                Sign Out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <RequestAccessModal :lga="lga" />
  </div>
</template>
<script>
import NotificationsDropdown from "@/components/NotificationsDropdown";
import { mapGetters } from "vuex";
import PWAInstall from "@/mixins/PWAInstall";
import FirebaseCloudMessaging from "@/mixins/FirebaseCloudMessaging";
import { PACKAGE_VERSION } from "@/version";
import RequestAccessModal from "@/components/RequestAccessModal";
import { auth } from "@/firebase/config";

export default {
  name: "top-nav",

  components: { NotificationsDropdown, RequestAccessModal },

  mixins: [PWAInstall, FirebaseCloudMessaging],

  data: () => ({
    lga: {},
  }),

  computed: {
    ...mapGetters("auth", ["user", "authed", "userType", "staff", "admin"]),
    ...mapGetters("customers", [
      "activeCustomer",
      "isValidSubscriber",
      "isExCustomer",
    ]),
    ...mapGetters("userData", [
      "profile",
      "assignedLGAs",
      "managedLGAs",
      "moderatedLGAs",
      "administeredLGAs",
      "exportableLGAs",
      "accessRequests",
    ]),

    canRequestAccessToLGA() {
      return (
        !this.activeCustomer?.loading &&
        !this.isNewCustomer &&
        this.assignedLGAs?.length === 0 &&
        this.accessRequests?.length === 0
      );
    },

    canModerateIncidentReports() {
      return Boolean(this.moderatedLGAs.length);
    },

    canAdministerClosures() {
      return Boolean(this.administeredLGAs.length);
    },

    canManageAccounts() {
      return Boolean(this.managedLGAs.length);
    },

    canExportContent() {
      return Boolean(this.exportableLGAs.length);
    },

    version() {
      return PACKAGE_VERSION;
    },

    isNewCustomer() {
      return (
        !this.activeCustomer?.loading &&
        this.staff &&
        !this.isValidSubscriber &&
        !this.isExCustomer
      );
    },
  },

  watch: {
    async managedLGAs(lgas) {
      if (!lgas?.length) return;
      const first = lgas[0];
      await this.$store.dispatch("customers/loadCustomer", first.lga_code);
    },
  },

  methods: {
    async onRequestAccess() {
      const user = auth.currentUser;

      if (!user) {
        throw new Error(
          "You must be signed in as a staff member to request access to an LGA"
        );
      }

      const { claims } = await user.getIdTokenResult(true);

      if (!claims.managed) {
        throw new Error(
          "You don't have permissions to request access to an LGA"
        );
      }

      const lga = await this.$store.dispatch(
        "lgaData/loadSingleLGA",
        claims.managed
      );
      this.lga = lga.properties;
      this.$bvModal.show("request-access-modal");
    },
  },
};
</script>

<style scoped></style>
